/* eslint-disable react/jsx-one-expression-per-line */
import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const StandOut = styled.section`
  margin: 0 -20px 120px;

  .stand-out {
    background: rgb(84, 26, 76);
    align-items: center;
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(1, 1fr);
    padding: 40px;

    h2 {
      margin-top: 0!important;
      padding: 0 0 1rem;
      text-align: left;
    }

    &__image {
      text-align: center;
    }
  }

  h3 {
    span {
      display: block;
    }
  }

  ul {
    list-style: none;
    padding: 0;
  }

  @media only screen and (min-width: 800px) {
    .stand-out {
      grid-template-columns: 46% 50%;
      justify-content: space-between;
    }

    ul, h2 {
      margin: 0 auto;
      max-width: 530px;
    }
  }

  @media only screen and (min-width: 1400px) {
    .stand-out {
      margin-left: 0;
      margin-right: 0;

      h2 {
        padding: 4rem 35px 0;
      }
      ul {
        padding: 25px 35px;
      }
    }
  }
`;

const StandOutContent = () => {
  const data = useStaticQuery(graphql`
    {
      old_coins: file(relativePath: {regex: "/srebrne-i-zlote-monety/"}) {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: BLURRED
            width: 540
          )
        }
      }
    }
  `);

  return (
    <StandOut>
      {/* Marcin Zamorski -> Portal Numizmatyczny (https://www.facebook.com/groups/portalnumizmatyczny/posts/3564930683755185/) */}

      <div className="stand-out">
        <div className="stand-out__content">
          <h2 className="text-center">Co nas wyróżnia?</h2>
          <ul>
            <li>
              <h3 style={{ marginTop: '20px' }}>
                <span>Indywidualne</span>
                <span>podejście do klienta</span>
              </h3>
              <p>Jesteśmy tu dla Ciebie! Nasze podejście jest oparte na&nbsp;indywidualnych potrzebach klienta.</p>
              <p>Niezależnie od tego, czy chcesz sprzedać całą kolekcję czy tylko kilka monet lub banknotów, zapewniamy profesjonalną obsługę i&nbsp;pełne wsparcie.</p>
            </li>

            <li>
              <h3>
                <span>Wsparcie dla początkujących</span>
                <span>i&nbsp;zaawansowanych numizmatyków</span>
              </h3>
              <p>Niezależnie od poziomu doświadczenia, pomożemy Ci&nbsp;zacząć przygodę z&nbsp;numizmatyką lub rozwinąć Twoją&nbsp;istniejącą kolekcję.</p>
            </li>
          </ul>
        </div>

        <div className="stand-out__image">
          <GatsbyImage
            image={getImage(data.old_coins)}
            alt="srebrne i złote monety kolekcjonerskie"
          />
        </div>
      </div>
    </StandOut>
  );
};

export default StandOutContent;
