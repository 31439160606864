/* eslint-disable react/jsx-one-expression-per-line */
import * as React from 'react';
import styled from 'styled-components';

const Socialmedia = styled.section`
  padding: 0 0 50px;
  text-align: left;

  h2 {
    margin-bottom: 2rem;
    text-align: center;
  }

  p {
    &:last-child {
      text-align: center;
    }
  }

  .socialmedia__desc {
    margin: 0 auto;
    max-width: 680px;

    a {
      display: inline-block;
    }
  }
`;

const SocialMediaContent = () => (
  <Socialmedia>
    <h2><span>Dołącz do społeczności</span></h2>

    <div className="socialmedia__desc">
      <p>Chcesz być na bieżąco z&nbsp;wydarzeniami ze <strong>świata numizmatyki</strong>, a&nbsp;może nie chcesz przegapić największych <strong>aukcji numizmatycznych</strong>, które są organizowane w&nbsp;Polsce.</p>
      <p style={{ marginBottom: '25px' }}>Dołącz do naszej społeczności na Facebooku:</p>

      <p>
        <a href="https://www.facebook.com/skup.monet.olsztyn" rel="nofollow" title="Monety i Banknoty Olsztyn" className="btn--white">
          <img
            src="/fb.svg"
            width="35"
            height="35"
            alt="facebook"
            style={{
              verticalAlign: 'middle',
            }}
          />Monety Olsztyn
        </a>
      </p>
    </div>
  </Socialmedia>
);

export default SocialMediaContent;
