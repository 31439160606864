/* eslint-disable react/jsx-one-expression-per-line */
import * as React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Intro from '../components/Home/Intro';
import WhatWeBuy from '../components/Home/WhatWeBuy';
import StandOut from '../components/Home/StandOut';
import SocialMedia from '../components/Home/SocialMedia';
// import RoadMap from '../components/Home/RoadMap';
import Testimonials from '../components/Home/Testimonials';
// import Blog from '../components/Home/Blog';

const MsgInfo = styled.div`
  border-left: 3px solid #5773c7;
  background: rgb(31 140 167 / 20%);
  max-width: 770px;
  border-radius: 3px;
  display: block;
  margin: 5px 0 25px;
  padding: 15px 20px;
  line-height: 140%;

  p {
    display: inline;
    margin: 0 5px 0 0;

    &:last-child {
      display: block;
    }
  }

  a {
    color: #7c9dff;
    border-color: #7c9dff;

    &:hover,
    &:focus {
      color: #fff;
      border-color: #fff;
    }
  }

  @media only screen and (min-width: 640px) {
    margin: 25px 0;
    padding: 20px 0 20px 25px;

    p {
      &:first-child {
        display: block;
      }
    }
  }

  @media only screen and (min-width: 800px) {
    p {
      &:last-child {
        display: inline;
      }
    }
  }
`;

const vacation = false;
const vacationDate = '30.05 – 02.06.2024';
const siteMetadata = {
  title: 'Monety Olsztyn - skup i wycena starych monet i banknotów',
  description: 'Skupujemy monety srebrne, monety złote, monety kolekcjonerskie, monety próbne, banknoty obiegowe, banknoty kolekcjonerskie, banknoty PRL.',
  siteName: 'Monety Olsztyn',
  imageUrl: 'https://monety.olsztyn.pl/favicons/splash-screen.png',
  siteUrl: 'https://monety.olsztyn.pl/',
  locale: 'pl_PL',
};

const IndexPage = () => (
  <>
    <Helmet htmlAttributes={{ lang: 'pl' }}>
      <title>{siteMetadata.title}</title>
      <meta name="description" content={siteMetadata.description} />
      <meta property="og:locale" content={siteMetadata.locale} />
      <meta property="og:site_name" content={siteMetadata.siteName} />
      <meta property="og:image" content={siteMetadata.imageUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={siteMetadata.siteUrl} />
      <meta property="og:title" content={siteMetadata.title} />
      <meta property="og:description" content={siteMetadata.description} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={siteMetadata.title} />
      <meta name="twitter:description" content={siteMetadata.description} />
      <meta name="twitter:image" content={siteMetadata.imageUrl} />

      <link rel="canonical" href={siteMetadata.siteUrl} />
    </Helmet>

    { vacation
      ? (
        <MsgInfo>
          <p>
            W dniach <strong>{ vacationDate }</strong> jesteśmy na urlopie.
          </p>
          <p>
            Zapraszamy do wysyłania pytań na <a href="mailto:kontakt@monety.olsztyn.pl">kontakt@monety.olsztyn.pl</a>.
          </p>
          <p>Wracamy w&nbsp;poniedziałek.</p>
        </MsgInfo>
      ) : '' }

    <Intro />

    <WhatWeBuy />

    <StandOut />

    {/* <Blog /> */}

    <Testimonials />

    <SocialMedia />
  </>
);

export default IndexPage;
