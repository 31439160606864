/* eslint-disable react/no-danger */
import * as React from 'react';
import styled from 'styled-components';
import Swiper from 'react-id-swiper';
import 'swiper/css/bundle';
import SwiperCore, { Autoplay, Pagination } from 'swiper';

SwiperCore.use([Autoplay, Pagination]);

const TestimonialsSection = styled.section`
  margin: 0 0 80px;

  .heading {
    display: grid;
    margin: 0 0 24px;

    &__button {
      display: none;
    }

    h2 {
      padding: 0;
      text-align: center;
    }

    > div {
      text-align: right;
    }

    @media only screen and (min-width: 640px) {
      align-items: center;
      grid-template-columns: 50% 1fr;

      &__button {
        display: block;
      }

      h2 {
        text-align: left;
      }
    }
  }

  .swiper-pagination {
    position: relative;
    z-index: 1;

    &-bullet {
      transition: opacity 200ms ease-in;
      background-color: #5dd882;
      opacity: .25;

      &-active {
        opacity: 1;
      }
    }
  }
`;

const SwiperWrapper = styled.div`
  padding: 50px 2px 0;
  overflow: hidden;

  .list-testimonials__item {
    border: 1px solid rgba(199,87,132,0.25);
    border-radius: 5px;
    box-shadow: 4px 5px 12px -4px rgba(0,0,0,.25);
    margin: 0 0 45px;
    padding: 20px 20px 2px;
    position: relative;

    &::before {
      background: url('/quote.svg') no-repeat 0 0;
      content: '';
      height: 29px;
      right: 15px;
      opacity: .65;
      top: -18px;
      width: 33px;
      position: absolute;
    }
  }

  .list-testimonials__desc {
    padding: 10px 0;

    @media only screen and (min-width: 1600px) {
      font-size: 1.8rem;
    }
  }

  .list-testimonials__rating {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: -10px 0 15px;

    i {
      background: url('/star.svg') no-repeat 0 0;
      display: inline-block;
      height: 15px;
      margin-right: 1px;
      width: 15px;
    }

    span {
      color: #c75784;
      font-size: 15px;
      font-weight: 600;
      padding-left: 10px;
    }

    img {
      width: 15px;
    }
  }

  .list-testimonials__cite {
    font-style: italic;
    margin: 0 0 20px;
    min-height: 90px;
    line-height: 160%;

    @media only screen and (min-width: 640px) and (max-width: 1200px) {
      min-height: 110px;
    }

    span {
      font-style: normal;
    }
  }

  .list-testimonials__footer {
    align-items: center;
    border-top: 1px solid rgba(199,87,132,0.25);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 60px;
    padding: 1px 2px 3px;

    p {
      font-weight: 600;
      margin: 0;

      @media only screen and (min-width: 1600px) {
        font-size: 1.8rem;
      }
    }

    figure {
      margin: 0;
    }

    img {
      position: relative;
      top: 2px;
      width: 30px;
    }
  }
`;

const TestimonialsItem = ({ year, msg, author }) => (
  <div className="swiper-slide">
    <div className="list-testimonials__item">
      <div className="list-testimonials__desc">
        <div className="list-testimonials__rating">
          <div>
            <i className="ic--star"></i>
            <i className="ic--star"></i>
            <i className="ic--star"></i>
            <i className="ic--star"></i>
            <i className="ic--star"></i>
          </div>
          <span className="testimonials-date testimonials-8">{year}</span>
        </div>
        <blockquote className="list-testimonials__cite" dangerouslySetInnerHTML={{ __html: msg }} />
      </div>
      <footer className="list-testimonials__footer">
        <p>{author}</p>
      </footer>
    </div>
  </div>
);

const sliderParams = {
  autoplay: {
    delay: 6000,
    disableOnInteraction: true,
  },
  breakpoints: {
    480: {
      slidesPerView: 1,
      spaceBetween: 0,
    },
    640: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    860: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
  },
  direction: 'horizontal',
  grabCursor: true,
  loop: true,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
  },
};

const Testimonials = () => (
  <TestimonialsSection>
    <div className="heading">
      <h2>Opinie naszych klientów</h2>
      <div className="heading__button">
        <a href="https://www.google.pl/search?sca_esv=247eb50602a83f19&tbm=lcl&sxsrf=ADLYWIJQtmrTXiFsHhKwDPBSKGHKOzaw5w:1722008718273&q=Monety+Olsztyn+Opinie&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxIxNLCwMDM0MzA2NzY0NjEyMDQ2sNzAyPiKUdQ3Py-1pFLBP6e4qqQyT8G_IDMvM3URK3ZxAFe8QZ5LAAAA&rldimm=10886160373134201309&hl=pl-PL&sa=X&ved=2ahUKEwiHrs-vhsWHAxVkHBAIHaQOON0Q9fQKegQIQBAF&biw=2560&bih=1232&dpr=1#lkt=LocalPoiReviews&mpd=~1643807909112649266/customers/reviews" className="btn--white">
          <img src="/google.svg" alt="Google" width="30" height="31" />
          Zobacz więcej opinii
        </a>
      </div>
    </div>

    <SwiperWrapper>
      <Swiper
        {...sliderParams}
      >
        <TestimonialsItem
          year="4 tygodnie temu"
          msg="Doskonała i&nbsp;świadcząca o&nbsp;wiedzy obsługa klienta oczywiście bezpłatna.<br />Bardzo polecam."
          author="lord vader"
        />
        <TestimonialsItem
          year="miesiąc temu"
          msg="Szybka i profesjonalna wycena monet polecam jak najbardziej <span>😉</span>"
          author="Paweł Burtan"
        />
        <TestimonialsItem
          year="6 miesięcy temu"
          msg="Pełen profesjonalizm miła obsługa i&nbsp;rzetelność polecam"
          author="Piotr Krupa"
        />
        <TestimonialsItem
          year="7 miesięcy temu"
          msg="Sprawna, uczciwa transakcja."
          author="Antonina Maria Nowak"
        />
        <TestimonialsItem
          year="10 miesięcy temu"
          msg="Transakcja super rzetelna!<br />
            Spawnie..a przede wszystkim profesjonalnie.<br />
            Polecam"
          author="Anna Hawana"
        />
        <TestimonialsItem
          year="11 miesięcy temu"
          msg="Bardzo rzetelna i&nbsp;uczciwa wycena. Polecam w&nbsp;100% <span>😃</span>"
          author="Zofia Janda"
        />
        <TestimonialsItem
          year="1 rok temu"
          msg="Bardzo polecam!"
          author="Kostek Saw"
        />
        <TestimonialsItem
          year="1 rok temu"
          msg="Super wycena sprawnie i&nbsp;bez ściemy polecam&nbsp;w&nbsp;100%"
          author="Grzegorz Ludkiewicz"
        />
      </Swiper>
    </SwiperWrapper>
  </TestimonialsSection>
);

export default Testimonials;
